import * as _util3 from "element-ui/lib/utils/util";
var _util2 = "default" in _util3 ? _util3.default : _util3;
var exports = {};
exports.__esModule = true;
var _util = _util2;

/**
 * Show migrating guide in browser console.
 *
 * Usage:
 * import Migrating from 'element-ui/src/mixins/migrating';
 *
 * mixins: [Migrating]
 *
 * add getMigratingConfig method for your component.
 *  getMigratingConfig() {
 *    return {
 *      props: {
 *        'allow-no-selection': 'allow-no-selection is removed.',
 *        'selection-mode': 'selection-mode is removed.'
 *      },
 *      events: {
 *        selectionchange: 'selectionchange is renamed to selection-change.'
 *      }
 *    };
 *  },
 */
exports.default = {
  mounted: function mounted() {
    return;
    if (!this.$vnode) return;
    var _getMigratingConfig = this.getMigratingConfig(),
      _getMigratingConfig$p = _getMigratingConfig.props,
      props = _getMigratingConfig$p === undefined ? {} : _getMigratingConfig$p,
      _getMigratingConfig$e = _getMigratingConfig.events,
      events = _getMigratingConfig$e === undefined ? {} : _getMigratingConfig$e;
    var _$vnode = this.$vnode,
      data = _$vnode.data,
      componentOptions = _$vnode.componentOptions;
    var definedProps = data.attrs || {};
    var definedEvents = componentOptions.listeners || {};
    for (var propName in definedProps) {
      propName = (0, _util.kebabCase)(propName); // compatible with camel case
      if (props[propName]) {
        console.warn("[Element Migrating][" + this.$options.name + "][Attribute]: " + props[propName]);
      }
    }
    for (var eventName in definedEvents) {
      eventName = (0, _util.kebabCase)(eventName); // compatible with camel case
      if (events[eventName]) {
        console.warn("[Element Migrating][" + this.$options.name + "][Event]: " + events[eventName]);
      }
    }
  },
  methods: {
    getMigratingConfig: function getMigratingConfig() {
      return {
        props: {},
        events: {}
      };
    }
  }
};
export default exports;
export const __esModule = exports.__esModule;